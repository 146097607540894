import React from 'react';
import { BLOCKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import styled from 'styled-components';


export default function BlogPageTemplate(props){
    const document = props.pageContext.body;
    const { title, date, client } = props.pageContext;

    const imgWrapperStyles = {
      display: 'block',
      margin: '8px auto',
      maxWidth: '600px',
      maxHeight: '450px'
    }

    const options = {
        renderNode: {
          "embedded-asset-block": (node) => {
            const imgSrc = node.data.target.fields.file['en-US'].url;
          return (
            <div style={imgWrapperStyles}>
              <img style={{ display: 'block', margin: '0 auto', boxShadow: '0 0 5px gray' }} src={imgSrc}/>
            </div>
          ) 
          }
        }
      };
    return(
        <PostWrapper style={{ width: '96%', margin: '0 auto', maxWidth: '650px' }}>
          <h1>{title}</h1>
          <p style={{ marginTop: '40px' }}>Luke Woodard</p>
          <p style={{ marginBottom: '48px', fontSize: '14px' }}>{date}</p>
          {documentToReactComponents(document, options)}
        </PostWrapper>
    )
}

const PostWrapper = styled.div`
  font-family: 'crimson-text', Garamond, serif
`